import React from 'react';
import config from '../../config';
import { Newspaper, FileText } from 'lucide-react';

const InstagramGenerator = ({ selectedArticles, setGeneratedContent, setIsLoading, setError }) => {
  const handleGenerateInstagram = async () => {
    setIsLoading(true);
    setError(null);
    try {
      setGeneratedContent("Zusammenfassung wird generiert...");
      
      const articleTexts = selectedArticles.map(article => `${article.title}\n\n${article.text}`);
      
      const response = await fetch(`${config.apiUrl}/article/create-instagram-post`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          texts: articleTexts,
        })
      });

      if (!response.ok) {
        throw new Error("Failed to generate instagram post");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value);
        const lines = chunk.split('|END|');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') break;
            accumulatedResponse += data;
            setGeneratedContent(accumulatedResponse);
          }
        }
      }
      const sourcesText = selectedArticles.map(article => `- ${article.title}: ${article.url}`).join('\n');
      setGeneratedContent(accumulatedResponse + '\n\n' + sourcesText)

      
    } catch (error) {
      console.error('Failed to generate instagram post:', error);
      setError(error.message);
      setGeneratedContent("");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto">
      <div className="mb-6">
        <h3 className="text-2xl font-bold mb-4 text-gray-800 flex items-center">
          <Newspaper className="mr-2" size={24} />
          Ausgewählte Artikel
        </h3>
        {selectedArticles.length > 0 ? (
          <ul className="space-y-2">
            {selectedArticles.map(article => (
              <li key={article.id} className="flex items-center text-gray-700">
                <FileText className="mr-2" size={16} />
                {article.title}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 italic">Keine Artikel ausgewählt</p>
        )}
      </div>

      <button
        onClick={handleGenerateInstagram}
        disabled={selectedArticles.length === 0}
        className="w-full bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed font-semibold text-lg flex items-center justify-center"
      >
        <FileText className="mr-2" size={20} />
        Instagram Post generieren
      </button>
    </div>
  );
};

export default InstagramGenerator;