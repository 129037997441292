import React from 'react';
import { Mail, MessageSquare, Settings } from 'lucide-react';

function Contact() {
  const styles = {
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 100%)'
    },
    container: {
      width: '100%',
      maxWidth: '800px',
      margin: '40px auto',
      padding: '60px 40px',
      borderRadius: '16px',
      background: 'rgba(255, 255, 255, 0.15)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    },
    header: {
      fontSize: '2.5rem',
      color: '#2d3748',
      marginBottom: '40px',
      textAlign: 'center',
      fontWeight: '700'
    },
    content: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '30px',
      marginTop: '40px'
    },
    contactItem: {
      padding: '30px',
      borderRadius: '12px',
      background: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(5px)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      transition: 'transform 0.2s, box-shadow 0.2s',
      cursor: 'pointer',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
      }
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '20px',
      width: '100%'
    },
    title: {
      fontSize: '1.25rem',
      color: '#2d3748',
      marginBottom: '15px',
      fontWeight: '600'
    },
    text: {
      color: '#4a5568',
      fontSize: '1.1rem',
      textDecoration: 'none',
      transition: 'color 0.2s',
      '&:hover': {
        color: '#2b6cb0'
      }
    },
    description: {
      textAlign: 'center',
      color: '#4a5568',
      maxWidth: '600px',
      margin: '0 auto 40px',
      lineHeight: '1.6',
      fontSize: '1.1rem'
    },
    highlight: {
      textAlign: 'center',
      color: '#2d3748',
      maxWidth: '600px',
      margin: '20px auto',
      padding: '15px',
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '8px',
      fontSize: '1.1rem',
      fontWeight: '500'
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.container}>
        <h1 style={styles.header}>Kontaktieren Sie uns</h1>

        <p style={styles.highlight}>
          Das Setup Ihres individuellen Themas ist kostenlos inklusive!
        </p>

        <div style={styles.content}>
          <div style={styles.contactItem}>
            <div style={styles.iconContainer}>
              <Mail size={32} color="#4a5568" />
            </div>
            <h2 style={styles.title}>E-Mail</h2>
            <a href="mailto:office@intelli-scout.com" style={styles.text}>
              office@intelli-scout.com
            </a>
          </div>

          <div style={styles.contactItem}>
            <div style={styles.iconContainer}>
              <MessageSquare size={32} color="#4a5568" />
            </div>
            <h2 style={styles.title}>Beratungsgespräch</h2>
            <p style={styles.text}>
              Vereinbaren Sie ein kostenloses Erstgespräch
            </p>
          </div>

          
        </div>
      </div>
    </div>
  );
}

export default Contact;