import React, { useState, useEffect } from 'react';
import config from '../config';
import { Link } from 'react-router-dom';

const StyleExamplesComponent = () => {
  const [contentTypeExamples, setContentTypeExamples] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchContentTypeExamples();
  }, []);

  const fetchContentTypeExamples = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/content-type-examples`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to fetch content type examples');
      const data = await response.json();
      setContentTypeExamples(data);
    } catch (error) {
      console.error('Error fetching content type examples:', error);
    }
  };

  const handleExampleTextChange = (contentTypeId, text) => {
    setContentTypeExamples(prev => prev.map(item => 
      item.content_type_id === contentTypeId ? {...item, example_text: text} : item
    ));
  };

  const saveExampleText = async (contentTypeId) => {
    setLoading(true);
    try {
      const exampleTextObj = contentTypeExamples.find(item => item.content_type_id === contentTypeId);
      const exampleText = exampleTextObj?.example_text || '';

      const response = await fetch(`${config.apiUrl}/content-type-example`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content_type_id: contentTypeId,
          example_text: exampleText
        }),
      });

      if (!response.ok) throw new Error('Failed to save example text');
      const data = await response.json();

      setContentTypeExamples(prev => prev.map(item => 
        item.content_type_id === contentTypeId 
          ? {...item, example_id: exampleText ? data.id : null, example_text: exampleText} 
          : item
      ));

      setMessage(exampleText ? 'Example text saved successfully' : 'Example text deleted successfully');
    } catch (error) {
      console.error('Error saving example text:', error);
      setMessage('Failed to save example text');
    } finally {
      setLoading(false);
    }
  };

  const deleteExampleText = async (contentTypeId) => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/content-type-example`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content_type_id: contentTypeId
        }),
      });

      if (!response.ok) throw new Error('Failed to delete example text');

      setContentTypeExamples(prev => prev.map(item => 
        item.content_type_id === contentTypeId 
          ? {...item, example_id: null, example_text: ''} 
          : item
      ));

      setMessage('Example text deleted successfully');
    } catch (error) {
      console.error('Error deleting example text:', error);
      setMessage('Failed to delete example text');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen">
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Style Hub</h1>

      <div className="bg-white bg-opacity-20 backdrop-blur-sm rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Stilvorlagen für Inhaltstypen</h2>
        <div className="space-y-6">
          {contentTypeExamples.map((item) => (
            <div key={item.content_type_id} className="p-4">
              <h3 className="text-lg font-medium mb-3 text-gray-800">{item.content_type_name}</h3>
              <textarea
                value={item.example_text}
                onChange={(e) => handleExampleTextChange(item.content_type_id, e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ease-in-out bg-white bg-opacity-50"
                rows="3"
                placeholder="Stilvorlage hier eintragen..."
              />
              <div className="mt-3 flex space-x-3">
                <button
                  onClick={() => saveExampleText(item.content_type_id)}
                  className="bg-green-500 bg-opacity-80 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50"
                  disabled={loading}
                >
                  Speichern
                </button>
                <button
                  onClick={() => deleteExampleText(item.content_type_id)}
                  className="bg-red-500 bg-opacity-80 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 disabled:opacity-50"
                  disabled={loading || !item.example_id}
                >
                  Löschen
                </button>
              </div>
            </div>
          ))}
        </div>
        {message && (
          <div className={`mt-6 p-3 rounded-lg ${message.includes('successfully') ? 'bg-green-100 bg-opacity-70 text-green-800' : 'bg-red-100 bg-opacity-70 text-red-800'}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  </div>

  );
};

export default StyleExamplesComponent;