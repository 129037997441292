import React from 'react';
import './PricingPage.css';

const PricingPage = () => {
  return (
    <div className="landing-page">
      <main>
        <section className="hero">
          <h1 className="text-2xl font-bold">Wählen Sie den perfekten Plan für Ihre Bedürfnisse</h1>
          <p>Starten Sie kostenlos mit unserem 7-tägigen Testzugang</p>
          <p className="text-sm text-gray-600">Inkl. 1 vordefiniertes Thema und begrenzte Textgenerierungen</p>
        </section>

        <section className="benefits">
          <div className="benefits-container grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto px-4">
            {/* Professional Plan */}
            <div className="feature-box hover:-translate-y-2 transition-transform duration-300 backdrop-blur-sm flex flex-col p-6 rounded-lg shadow-lg min-h-[500px]">
              <h3 className="font-bold text-xl">Professional</h3>
              <div className="mt-4 mb-6">
                <span className="text-3xl font-bold">60€</span>
                <span className="text-gray-600">/Monat</span>
              </div>
              <div className="space-y-4 text-left flex-grow">
                <p>✓ 1 Thema Ihrer Wahl (individuell anpassbar)</p>
                <p>✓ 500 Textgenerierungen pro Monat</p>
                <p>✓ 20 aggregierte Artikel pro Tag</p>
                <p>✓ News Hub Zugang</p>
                <p>✓ Content Hub Zugang</p>
                <p>✓ Style Personalisierung</p>
              </div>
              <div className="mt-8">
                <a href="/contact" className="cta-button block w-full text-center bg-[#ff6b6b] text-white px-6 py-3 rounded-lg hover:bg-[#ff5252] transition-colors">Jetzt starten</a>
                <p className="text-sm text-gray-500 text-center mt-2">Jederzeit kündbar</p>
              </div>
            </div>

            {/* Company Plan */}
            <div className="feature-box hover:-translate-y-2 transition-transform duration-300 relative overflow-hidden backdrop-blur-sm flex flex-col p-6 rounded-lg shadow-lg min-h-[500px]">
              <h3 className="font-bold text-xl">Company</h3>
              <div className="mt-4 mb-6">
                <span className="text-3xl font-bold">300€</span>
                <span className="text-gray-600">/Monat</span>
              </div>
              <div className="space-y-4 text-left flex-grow">
                <p>✓ Alle Professional Features</p>
                <p>✓ 1.500 Textgenerierungen pro Monat</p>
                <p>✓ 600 Bildgenerierungen pro Monat</p>
                <p>✓ 50 aggregierte Artikel pro Tag</p>
                <p>✓ Prioritäts-Support</p>
              </div>
              <div className="mt-8">
                <a href="/contact" className="cta-button block w-full text-center bg-[#ff6b6b] text-white px-6 py-3 rounded-lg hover:bg-[#ff5252] transition-colors">Jetzt starten</a>
                <p className="text-sm text-gray-500 text-center mt-2">Jederzeit kündbar</p>
              </div>
            </div>

            {/* Enterprise Plan */}
            <div className="feature-box hover:-translate-y-2 transition-transform duration-300 backdrop-blur-sm flex flex-col p-6 rounded-lg shadow-lg min-h-[500px]">
              <h3 className="font-bold text-xl">Enterprise</h3>
              <div className="mt-4 mb-6">
                <span className="text-xl font-bold">Individuell</span>
              </div>
              <div className="space-y-4 text-left flex-grow">
                <p>✓ Maßgeschneiderte Lösungen</p>
                <p>✓ Gemeinsame Entwicklung</p>
                <p>✓ Sound Integration</p>
                <p>✓ Dynamische Avatare</p>
                <p>✓ API-Zugang</p>
                <p>✓ Dedizierter Account Manager</p>
              </div>
              <div className="mt-8">
                <a href="/contact" className="cta-button block w-full text-center bg-[#ff6b6b] text-white px-6 py-3 rounded-lg hover:bg-[#ff5252] transition-colors">Kontaktieren Sie uns</a>
              </div>
            </div>
          </div>
        </section>

        <section className="cta bg-gray-50 py-16">
          <div className="max-w-2xl mx-auto text-center px-4">
            <h2 className="text-2xl font-bold mb-4">Kostenlos testen</h2>
            <p className="mb-6">Testen Sie IntelliScout 7 Tage lang kostenlos mit Zugriff auf ein vordefiniertes Thema und erste Textgenerierungen.</p>
            <a href="/registration" className="cta-button">Kostenlos starten</a>
          </div>
        </section>

        <section className="py-16">
          <div className="max-w-4xl mx-auto px-4">
            <h2 className="text-2xl font-bold text-center mb-8">Häufig gestellte Fragen</h2>
            <div className="space-y-6 bg-white/80 backdrop-blur p-8 rounded-lg shadow-lg">
              <div>
                <h3 className="font-bold mb-2">Was ist im kostenlosen Testzeitraum enthalten?</h3>
                <p className="text-gray-600">Sie erhalten 7 Tage lang Zugriff auf ein vordefiniertes Thema und können die grundlegenden Funktionen von IntelliScout testen.</p>
              </div>
              <div>
                <h3 className="font-bold mb-2">Kann ich meinen Plan später upgraden?</h3>
                <p className="text-gray-600">Ja, Sie können jederzeit zu einem höheren Plan wechseln. Die Preisanpassung erfolgt anteilig.</p>
              </div>
              <div>
                <h3 className="font-bold mb-2">Gibt es eine Mindestvertragslaufzeit?</h3>
                <p className="text-gray-600">Nein, Sie können monatlich kündigen. Bei jährlicher Zahlung profitieren Sie von zusätzlichen Rabatten.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PricingPage;