import 'chart.js/auto';
import React, { useEffect  } from 'react';

import '../App.css';
import '../feed/NewsArticleStyles.css';
import refreshIcon from '../images/refresh-outline.svg';
import copyIcon from '../images/copy-outline.svg'
import Section from '../Section';

import config from '../config';

const FacebookContainer = ({ article, postDisabled, post, copyFeedback, setCopyFeedback, hasServiceAccess, isLoading, setIsLoading, postText, setPostText, loadingImage, setLoadingImage, selectedImage, setSelectedImage }) => {

    const handleCreatePostButtonClick = async () => {
        setIsLoading(true);
        try {
          setPostText("Post wird generiert...");
          const response = await fetch(`${config.apiUrl}/article/create-facebook-post`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ texts: [article.summary] }),
          });

          if (!response.ok) {
            throw new Error('Failed to test prompt');
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let accumulatedResponse = '';

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value);
            const lines = chunk.split('|END|');

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                if (data === '[DONE]') break;
                accumulatedResponse += data;
                setPostText(accumulatedResponse);
              }
            }
          }

        } catch (error) {
          console.error('Failed to fetch the post text:', error);
        } finally {
          setIsLoading(false);
        }
    };

    useEffect(() => {
      if (!postText) handleCreatePostButtonClick()
    }, []);

    const handleGenerateImageButtonClick = async () => {
        setLoadingImage(true);
        setSelectedImage(null);
        try {
          const response = await fetch(`${config.apiUrl}/generate-image`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              text: postText,
            }),
          });
    
          const data = await response.json();
          if (response.ok) {
            setSelectedImage(data.imageUrl);
          } else {
            console.error('Image generation failed:', data.message);
          }
        } catch (error) {
          console.error('Error generating image:', error);
        }
        setLoadingImage(false);
    };
    const handleTextChange = (e) => {
        setPostText(e.target.value);
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        
        <div className="postTextSection">

                <div className="inputCard textArea">
                <div className="inputCard-content inputCard-content-overflow">
                    <div className="textarea-container">
                    
                        <textarea value={postText} onChange={handleTextChange} />
                        <button
                            className="refresh-button pointer"
                            onClick={handleCreatePostButtonClick}
                            disabled={isLoading}
                            title="Neu generieren"
                        >
                            <img src={refreshIcon} alt="Refresh" />
                        </button>
                        <button
                            className={`copy-button pointer ${copyFeedback ? 'animated' : ''}`}
                            onClick={() => {
                            navigator.clipboard.writeText(postText);
                            setCopyFeedback('Kopiert!');
                            setTimeout(() => setCopyFeedback(''), 2000);
                            }}
                            disabled={!postText}
                            title={copyFeedback || 'Text kopieren'}
                        >
                            <img src={copyIcon} alt="Copy" />
                        </button>
                        <span className="char-count">{postText?.length ?? 0} characters</span>
                       
                    </div>
                </div>
                </div>
                
            </div>
            <Section>
                <div className="centered">
                <div className="memeCard memeGenerator">
                    <div className="memeCard-content">
                    <div className="image-preview">
                        {selectedImage ? (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <img src={loadingImage ? '' : selectedImage}  alt="Preview" style={{ width: '100%', height: 'auto' }} />
                            <button
                            className="meme-refresh-button"
                            onClick={handleGenerateImageButtonClick}
                            disabled={loadingImage}
                            >
                            <img src={refreshIcon} alt="Refresh" />
                            </button>
                        </div>

                        ) : loadingImage ? (
                        <div className="loader"></div>
                        ) : (
                        <button className="generation-button"
                            onClick={handleGenerateImageButtonClick}
                            disabled={loadingImage}
                        >
                            Bild generieren
                        </button>
                        )}
                        
                    </div>
                    </div>
                </div>
                </div>
                

            </Section>
                
            <div className="centered">
                <div className="socials-row">
                    <button
                      className={(postDisabled) ? "post-disabled" : "post-send"}
                      onClick={post}
                      disabled={postDisabled}
                    >
                     Post
                    </button>
                </div>
            </div>
        </div>
    )

}

export default FacebookContainer;