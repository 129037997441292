import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, Alert } from '@mui/material';
import config from './config';

const ResetPassword = () => {
  const [passwords, setPasswords] = useState({ new_password: '', confirm_password: '' });
  const [status, setStatus] = useState({ message: '', severity: '' });
  const [isValidToken, setIsValidToken] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    validateToken();
  }, [token]);

  const validateToken = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/validate-reset-token/${token}`);
      const data = await response.json();
      setIsValidToken(data.valid);
      if (!data.valid) {
        setStatus({
          message: data.message || 'Ungültiger oder abgelaufener Link.',
          severity: 'error'
        });
      }
    } catch (error) {
      setStatus({
        message: 'Der Server konnte nicht erreicht werden.',
        severity: 'error'
      });
    }
  };

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ message: '', severity: '' });

    if (passwords.new_password !== passwords.confirm_password) {
      setStatus({
        message: 'Die Passwörter stimmen nicht überein.',
        severity: 'error'
      });
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          new_password: passwords.new_password
        })
      });

      const data = await response.json();
      if (response.ok) {
        setStatus({
          message: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
          severity: 'success'
        });
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setStatus({
          message: data.message || 'Ein Fehler ist aufgetreten.',
          severity: 'error'
        });
      }
    } catch (error) {
      setStatus({
        message: 'Der Server konnte nicht erreicht werden.',
        severity: 'error'
      });
    }
  };

  if (!isValidToken) {
    return (
      <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
        <Alert severity="error">
          {status.message}
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>
        Neues Passwort festlegen
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Neues Passwort"
              name="new_password"
              value={passwords.new_password}
              onChange={handleChange}
              variant="outlined"
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Passwort bestätigen"
              name="confirm_password"
              value={passwords.confirm_password}
              onChange={handleChange}
              variant="outlined"
              type="password"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Passwort zurücksetzen
            </Button>
          </Grid>
          {status.message && (
            <Grid item xs={12}>
              <Alert severity={status.severity}>{status.message}</Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default ResetPassword;
