import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Grid, Alert } from '@mui/material';
import config from './config';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ message: '', severity: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ message: '', severity: '' });

    try {
      const response = await fetch(`${config.apiUrl}/request-password-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();
      if (response.ok) {
        setStatus({
          message: 'Falls ein Konto mit dieser E-Mail existiert, wurde ein Reset-Link gesendet.',
          severity: 'success'
        });
        setEmail('');
      } else {
        setStatus({
          message: data.message || 'Ein Fehler ist aufgetreten.',
          severity: 'error'
        });
      }
    } catch (error) {
      setStatus({
        message: 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.',
        severity: 'error'
      });
    }
  };

  return (
    <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>
        Passwort zurücksetzen
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ marginBottom: '16px' }}>
              Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts zu erhalten.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-Mail-Adresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Link anfordern
            </Button>
          </Grid>
          {status.message && (
            <Grid item xs={12}>
              <Alert severity={status.severity}>{status.message}</Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default RequestPasswordReset;