import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <ul>
                <li><a className="underline" href="https://intelli-scout.com/api/static/t_c.pdf" target="_blank" rel="noopener noreferrer">Allgemeine Geschäftsbedingungen</a></li>
                <li><a className="underline" href="https://intelli-scout.com/api/static/privacy_policy.html" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a></li>
                <li><a className="underline" href="https://intelli-scout.com/api/static/impressum.pdf" target="_blank" rel="noopener noreferrer">Impressum</a></li>
                <li><Link className="underline" to="/contact">Kontakt</Link></li>
                <li><Link className="underline" to="/pricing">Pricing</Link></li>

            </ul>
        </footer>
    );
};

export default Footer;