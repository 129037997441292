import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, Alert } from '@mui/material';
import { UserContext } from './AuthProvider';
import config from './config';

const RegistrationForm = () => {
  const [user, setUser] = useState({ username: '', email: '', password: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser: setGlobalUser } = useContext(UserContext);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const registrationResponse = await fetch(`${config.apiUrl}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
      });

      const registrationData = await registrationResponse.json();
      if (registrationResponse.ok) {
        // Nach erfolgreicher Registrierung automatisch einloggen
        const loginResponse = await fetch(`${config.apiUrl}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: user.username, password: user.password }),
          credentials: 'include'
        });

        const loginData = await loginResponse.json();
        if (loginResponse.ok) {
          setGlobalUser(loginData.user);
          navigate('/profile'); // Geändert zu /profile
        } else {
          setError('Die automatische Anmeldung ist fehlgeschlagen. Bitte versuchen Sie sich manuell anzumelden.');
        }
      } else {
        setError('Die Registrierung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.');
      }
    } catch (error) {
      setError('Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>Registrierung</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Benutzername" 
              name="username" 
              value={user.username} 
              onChange={handleChange} 
              variant="outlined" 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="E-Mail-Adresse" 
              name="email" 
              value={user.email} 
              onChange={handleChange} 
              variant="outlined" 
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Passwort" 
              name="password" 
              value={user.password} 
              onChange={handleChange} 
              variant="outlined" 
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Passwort bestätigen" 
              name="confirmPassword" 
              value={user.confirmPassword} 
              onChange={handleChange} 
              variant="outlined" 
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Registrieren
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default RegistrationForm;