import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, Alert } from '@mui/material';
import { UserContext } from './AuthProvider';
import config from './config';

const LoginForm = () => {
  const [localUser, setLocalUser] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLocalUser({ ...localUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${config.apiUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(localUser),
        credentials: 'include'
      });

      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        navigate('/news-hub');
      } else {
        setError('Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.');
      }
    } catch (error) {
      setError('Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>Anmeldung</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Benutzername" 
              name="username" 
              value={localUser.username} 
              onChange={handleChange} 
              variant="outlined" 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Passwort" 
              name="password" 
              value={localUser.password} 
              onChange={handleChange} 
              variant="outlined" 
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Anmelden
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" style={{ marginTop: '10px', textAlign: 'center' }}>
              Passwort vergessen?{' '}
              <Link 
                to="/request-password-reset" 
                style={{ 
                  color: '#1976d2',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                Hier zurücksetzen
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ marginTop: '10px', textAlign: 'center' }}>
              Sie haben noch kein Konto?{' '}
              <Link 
                to="/registration" 
                style={{ 
                  color: '#1976d2',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'none'
                  }
                }}
              >
                Hier registrieren
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default LoginForm;