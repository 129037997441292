import React, { useState } from 'react';
import './LandingPage.css';
import config from '../config';
import webcrawler from '../images/news_hub.png';
import zusammenfassung from '../images/text_summary.png';
import bewertung from '../images/text_analysis.png';
import newshub from '../images/news_hub.webp';
import contenthub from '../images/content_hub.png';
import stylehub from '../images/style_hub.webp';
import newshub_big from '../images/news-hub.jpg';
import contenthub_big from '../images/content-hub.jpg';
import stylehub_big from '../images/style-hub.jpg';

import zeitersparnis from '../images/zeitersparnis.webp';
import skalierbarkeit from '../images/skalierbarkeit.webp';
import kosteneinsparung from '../images/kosteneinsparung.webp';
import wettbewerbsvorteil from '../images/wettbewerbsvorteil.webp';
import inhaltsquali from '../images/inhaltsquali.webp';
import produktivi from '../images/produktivi.webp';
import engagement from '../images/engagement.webp';
import benutzerfreundlichkeit from '../images/benutzerfreundlichkeit.webp';

import landingvideo from '../videos/landing.mp4';


const LandingPage = () => {
    const [selectedPreview, setSelectedPreview] = useState(null);

    const hubCards = [
        { 
            id: 'news', 
            title: 'News Hub', 
            image: newshub, 
            bigImage: newshub_big,
            description: 'Recherche Tool für vorgefilterte Artikel mit Evaluierung nach Qualität und Innovationsgrad.'
        },
        { 
            id: 'content', 
            title: 'Content Hub', 
            image: contenthub, 
            bigImage: contenthub_big,
            description: 'KI-gestützte Texterstellung für verschiedene Formate basierend auf beliebig vielen Quellartikeln. Selektion und Kombination von Artikeln für maßgeschneiderte Inhalte.'
        },
        { 
            id: 'style', 
            title: 'Style Hub', 
            image: stylehub, 
            bigImage: stylehub_big,
            description: 'Experimentelle Plattform für Format-spezifische Stilvorlagen. Garantiert konsistenten Stil bei der Content Creation, besonders für Social Media Inhalte.'
        }
    ];

    
    return (
        <div className="landing-page">
            <main>
            <section className="hero mx-auto max-w-4xl px-4 py-16 text-center">
                    <h1 className="font-bold">Quality Content auf dem nächsten Level</h1>
                    <div className="video-container mt-8 mb-12 mx-auto max-w-3xl">
                        <video 
                            className="w-full rounded-lg shadow-lg"
                            autoPlay 
                            muted 
                            loop 
                            playsInline
                            controls
                        >
                            <source src={landingvideo} type="video/mp4" />
                            Ihr Browser unterstützt das Video-Tag nicht.
                        </video>
                    </div>
                    <div className="space-y-4 mb-12 mt-12">
                        <p className="text-lg text-gray-700 font-bold px-4">
                        IntelliScout ist ein KI-gestütztes Tool zur innovativen Content Creation basierend auf individuell vorselektierten aktuellen „News“ mit integriertem Workflow und breiter Funktionalität speziell für die Medienbranche.                        </p>
                        <p className="text-lg text-gray-700 font-bold px-4">
                        IntelliScout versorgt Redaktionen täglich mit relevantem und hochwertigem Content speziell für die jeweiligen Zielgruppen und Communities.
                        </p>
                        
                    </div>

                    <a href="/registration" className="cta-button inline-block px-8 py-4 rounded-lg">
                        Jetzt kostenlos testen
                    </a>
                </section>

                <section className="features">
                    <h2 className="font-bold">Features</h2>
                    <div className="feature-container">
                        {hubCards.map(card => (
                            <div 
                                key={card.id}
                                className="feature-box"
                                onMouseEnter={() => setSelectedPreview(card)}
                                onMouseLeave={() => setSelectedPreview(null)}
                            >
                                <img src={card.image} alt={card.title} />
                                <h3 className="font-bold">{card.title}</h3>
                                <p>{card.description}</p>
                            </div>
                        ))}
                    </div>

                    {selectedPreview && (
                        <div className="preview-modal">
                            <img 
                                src={selectedPreview.bigImage} 
                                alt={`${selectedPreview.title} Preview`}
                            />
                        </div>
                    )}

                    <div className="feature-container">
                        <div className="feature-box">
                            <img src={webcrawler} alt="Web-Crawling-Symbol" />
                            <h3 className="font-bold">Web-Crawling</h3>
                            <p>Automatisches Erfassen und Übersetzen von relevantem Content aus dem gesamten Web</p>
                        </div>
                        <div className="feature-box">
                            <img src={zusammenfassung} alt="Zusammenfassungssymbol" />
                            <h3 className="font-bold">Zusammenfassung</h3>
                            <p>Erfassen Sie schnell die Kernpunkte mit prägnanten Zusammenfassungen</p>
                        </div>
                        <div className="feature-box">
                            <img src={bewertung} alt="Bewertungssymbol" />
                            <h3 className="font-bold">Evaluierung</h3>
                            <p>KI-gestütze Evaluierung nach Relevanz und Innovation</p>
                        </div>
                    </div>
                </section>

                <section className="benefits">
                    <h2 className="font-bold">Benefits</h2>
                    <div className="benefits-container">
                        <div className="feature-box">
                            <img src={zeitersparnis} alt="Zeitersparnis" />
                            <h3 className="font-bold">Zeitersparnis</h3>
                            <p>Konzentrieren Sie sich auf die wichtigsten Informationen</p>
                        </div>
                        <div className="feature-box">
                            <img src={skalierbarkeit} alt="Skalierbarkeit" />
                            <h3 className="font-bold">Skalierbarkeit</h3>
                            <p>Skalieren Sie Ihre Content-Erstellung, ohne zusätzliche Ressourcen zu benötigen</p>
                        </div>
                        <div className="feature-box">
                            <img src={kosteneinsparung} alt="Kosteneinsparungen" />
                            <h3 className="font-bold">Kosteneinsparungen</h3>
                            <p>Nutzen Sie eine erschwingliche Lösung, die Ihnen hilft, Ihr Content-Marketing optimal zu skalieren</p>
                        </div>
                        <div className="feature-box">
                            <img src={wettbewerbsvorteil} alt="Wettbewerbsvorteil" />
                            <h3 className="font-bold">Wettbewerbsvorteil</h3>
                            <p>Aktuelles, zielgruppenoptimiertes Veröffentlichen relevanter Inhalte</p>
                        </div>
                        <div className="feature-box">
                            <img src={inhaltsquali} alt="Verbesserte Inhaltsqualität" />
                            <h3 className="font-bold">Quality Content</h3>
                            <p>Erstellen Sie täglich aktuelle und hochwertige Inhalte in hochwertigster Übersetzung</p>
                        </div>
                        <div className="feature-box">
                            <img src={produktivi} alt="Gesteigerte Produktivität" />
                            <h3 className="font-bold">Gesteigerte Produktivität</h3>
                            <p>Optimieren Sie Ihren Content-Creation-Prozess und konzentrieren Sie sich auf strategische Aufgaben</p>
                        </div>
                        <div className="feature-box">
                            <img src={engagement} alt="Erhöhtes Engagement" />
                            <h3 className="font-bold">Erhöhtes Engagement</h3>
                            <p>Fördern Sie Interaktionen, Shares und Diskussionen durch hochwertige Beiträge</p>
                        </div>
                        <div className="feature-box">
                            <img src={benutzerfreundlichkeit} alt="Benutzerfreundlichkeit" />
                            <h3 className="font-bold">Benutzer- freundlichkeit</h3>
                            <p>Profitieren Sie von einer intuitiven und benutzerfreundlichen Oberfläche</p>
                        </div>
                    </div>
                </section>

                <section className="cta">
                    <a href="/contact" className="cta-button">Kontaktieren Sie uns</a>
                    <br></br>
                    <br></br>
                    <br></br>

                </section>
            </main>
        </div>
    );
};

export default LandingPage;